import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { getAvailableDate } from "../utils"

import styles from "./footer.module.scss"

import Container from "./container"
import SvgChevronRight from "../icons/chevron-right.inline.svg"
import SvgTwitter from "../icons/twitter.inline.svg"
import SvgGithub from "../icons/github.inline.svg"
import SvgLinkedIn from "../icons/linkedin.inline.svg"

const Footer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            profile {
              bio
              availability
            }
          }
        }

        file(relativePath: { eq: "avatar-small@2x.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 240) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const profile = query.site.siteMetadata.profile

  let availableDate = getAvailableDate(profile.availability)

  return (
    <footer className={classNames(styles.Footer, "theme--dark")}>
      <Container>
        <div className={classNames(styles.grid)}>
          <div className={styles.col1}>
            <div className={styles.bio}>
              <div className={styles.bioAvatar}>
                <Img
                  fadeIn={false}
                  fluid={query.file.childImageSharp.fluid}
                  alt="Photo of Ian Lunn"
                  width="120"
                  height="auto"
                />
              </div>
              <p
                className={styles.bioDesc}
                dangerouslySetInnerHTML={{ __html: profile.bio }}
              />
            </div>

            <p>
              Front-end Developer.
              <br />
              <span className={styles.name}>
                <Link to="/contact" className={styles.line}>
                  Available {availableDate}. Hire Now{" "}
                  <SvgChevronRight
                    className={styles.lineIcon}
                    fill="white"
                    height=".875em"
                  />
                </Link>
              </span>
            </p>
          </div>
          <div className={styles.col2}>
            <dl className={styles.sitemap}>
              <div>
                <dt className={styles.name}>
                  <a href="https://twitter.com/IanLunn/">
                    <span className={styles.icon}>
                      <SvgTwitter fill="white" width="1em" height="1em" />
                    </span>
                    Twitter
                  </a>
                </dt>
                <dd className={styles.desc}>
                  Follow for web design & development articles, opinions, and
                  links
                </dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <a href="https://github.com/IanLunn/">
                    <span className={styles.icon}>
                      <SvgGithub fill="white" width="1em" height="1em" />
                    </span>
                    GitHub
                  </a>
                </dt>
                <dd className={styles.desc}>
                  Follow for open-source projects such as{" "}
                  <a href="https://github.com/IanLunn/Hover">Hover.css</a>,{" "}
                  <a href="https://github.com/IanLunn/Sequence">Sequence.js</a>,
                  and{" "}
                  <a href="https://github.com/IanLunn/jQuery-Parallax">
                    jQuery Parallax
                  </a>
                </dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <a href="https://uk.linkedin.com/in/ianlunn/">
                    <span className={styles.icon}>
                      <SvgLinkedIn fill="white" width="1em" height="1em" />
                    </span>
                    LinkedIn
                  </a>
                </dt>
                <dd className={styles.desc}>Let's connect on LinkedIn</dd>
              </div>
            </dl>
          </div>
          <div className={styles.col3}>
            <dl className={styles.sitemap}>
              <div>
                <dt className={styles.name}>
                  <Link to="/about">About</Link>
                </dt>
                <dd className={styles.desc}>
                  Learn about Ian's skills and workflow
                </dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <Link to="/">Portfolio</Link>
                </dt>
                <dd className={styles.desc}>View Ian's previous work</dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <Link to="/testimonials">Testimonials</Link>
                </dt>
                <dd className={styles.desc}>Read client recommendations</dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <Link to="/articles">Articles</Link>
                </dt>
                <dd className={styles.desc}>
                  Read web related articles, opinions & tutorials
                </dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <a
                    href="https://store.ianlunn.co.uk"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    Store
                  </a>
                </dt>
                <dd className={styles.desc}>
                  Simple & stylish UI to add to your front-end development
                  toolbox
                </dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <Link to="/contact">Get In Touch</Link>
                </dt>
                <dd className={styles.desc}>Send a general message</dd>
              </div>
              <div>
                <dt className={styles.name}>
                  <Link to="/contact">Hire Ian</Link>
                </dt>
                <dd className={styles.desc}>Enquire about hiring Ian</dd>
              </div>
            </dl>
          </div>
        </div>

        <small className={styles.legal}>
          Copyright © {new Date().getFullYear()}
          {` `}
          <a href="https://www.ianlunn.co.uk">Ian Lunn Design Limited</a>
        </small>
      </Container>
    </footer>
  )
}

export default Footer
