import React, { useState } from "react"
import PropTypes from "prop-types"
import Media from "react-media"
import classnames from "classnames"

import "../scss/global/layout.global.scss"

import links from "../data/links"
import Header from "./header"
import Footer from "./footer"
import NewDrawer from "./newDrawer"
import SideMenu from "./sideMenu"

const Layout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  if (typeof window !== `undefined`) {
    window.addEventListener("keydown", e => {
      if (isDrawerOpen && e.which === 27) {
        setIsDrawerOpen(false)
      }
    })
  }

  return (
    <div className={classnames("canvas", isDrawerOpen ? "canvasOpen" : "")}>
      <Header
        links={links}
        isMenuOpen={isDrawerOpen}
        toggleMobileMenu={() => setIsDrawerOpen(!isDrawerOpen)}
      />
      <Media queries={{ small: { maxWidth: 959 } }}>
        {matches =>
          matches.small ? (
            <div>
              <NewDrawer
                isOpen={isDrawerOpen}
                closeMenu={() => setIsDrawerOpen(false)}
              >
                <SideMenu links={links} />
              </NewDrawer>
            </div>
          ) : null
        }
      </Media>
      <main id="content" className={classnames("content")}>
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
