import React from "react"
import classnames from "classnames"

import styles from "./container.module.scss"

const Container = ({ maxWidth, className, children }) => (
  <div
    className={classnames(styles.container, className)}
    style={{ maxWidth: `${maxWidth}` }}
  >
    {children}
  </div>
)

Container.defaultProps = {
  maxWidth: "",
  className: "",
}

export default Container
