import React from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"
import classnames from "classnames"

import styles from "./menu.module.scss"

const Menu = ({ links }) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <nav className={styles.Menu}>
            <ul className={styles.list}>
              {links.map((link, index) => {
                return (
                  <li key={index} className={styles.item}>
                    <Link
                      className={classnames(
                        link.type === "button" ? styles.button : styles.link,
                        location.pathname.split("/")[1] ===
                          link.to.split("/")[1]
                          ? styles.active
                          : ""
                      )}
                      to={link.to}
                      activeClassName="active"
                    >
                      {link.text}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        )
      }}
    </Location>
  )
}

export default Menu
