import React from "react"
import { Link } from "gatsby"
import { Location } from "@reach/router"
import classnames from "classnames"
import styles from "./sideMenu.module.scss"

const SideMenu = ({ links, onNav }) => {
  function handleClick() {
    onNav()
  }

  return (
    <Location>
      {({ location }) => {
        return (
          <nav className={styles.SideMenu}>
            <div className={styles.container}>
              <ul className={styles.list}>
                {links.map((link, index) => (
                  <li key={index} className={styles.item}>
                    <Link
                      onClick={handleClick}
                      className={classnames(
                        link.type === "button" ? styles.button : styles.link,
                        location.pathname.split("/")[1] ===
                          link.to.split("/")[1]
                          ? styles.active
                          : ""
                      )}
                      to={link.to}
                      activeClassName="active"
                    >
                      {link.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
        )
      }}
    </Location>
  )
}

export default SideMenu
