import moment from "moment"

export function getAvailableDate(date) {
  date = moment(date)
  const now = moment()

  // If the availability date is older than today, use today's date instead
  if (date.diff(now) < 0) {
    date = now
  }

  return moment(date, "YYYY-MM-DD").format("MMMM YYYY")
}
